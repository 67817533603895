<template>
    <div class="page-tickets" :style="{height: `calc(100vh - ${$vuetify.application.top}px)`}">
        <support-view :i18n="getI18n">
            <template slot="ticket-actions" slot-scope="{ticket}">
                <v-btn dark icon @click="onClickDisplayInfo(ticket)">
                    <v-icon color="black">mdi-information-outline</v-icon>
                </v-btn>

                <v-menu bottom left v-if="ticket.Status === 'Open'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn dark icon v-bind="attrs" v-on="on">
                            <v-icon color="black">mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="onClickAssignTicket(ticket)">
                            <v-list-item-title>Назначить ответственного</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="closeTicket(ticket)">
                            <v-list-item-title>Решено</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </support-view>


        <v-dialog v-model="visibleInfo" max-width="500px">
            <v-card>
                <v-card-title>
                    Информация о пользователе
                </v-card-title>

                <v-list>
                    <template v-for="(key, value) in getMetadata">
                        <v-list-item :key="value">
                            <v-list-item-content style="padding: 0;">
                                <v-list-item-title>{{ value }}</v-list-item-title>
                                <v-list-item-subtitle>{{ key }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider :key="`d-${value}`"/>
                    </template>
                </v-list>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="visibleInfo = false">
                        Закрыть
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import SupportView from "storemoodify/lib/support/SupportView"

export default {
    components: { SupportView },
    head() {
        return {
            title: "Обращения пользователей | StoreMood",
            toolbar: "Обращения пользователей",
        }
    },
    data() {
        return {
            ticket: null,
            visibleInfo: false,
        }
    },
    computed: {
        getI18n() {
            return {
                "my tickets": "Назначенные мне",
                "opened": "Открыто",
                "ticket": "Обращение",
                "enter message": "Введите сообщение",
                "empty": "Мы не нашли ни одного обращения.",
                "inProgress": "В работе",
                "closed": "Закрыто",
                "ticket message": "Текст обращения",
                "ticket user": "Обращение открыто пользователем",
            }
        },

        getMetadata() {
            return this.ticket ? JSON.parse(this.ticket.Metadata) : {}
        },
    },
    methods: {
        ...mapActions("support", ["resolveTicket", "assignTicket"]),
        closeTicket(ticket) {
            this.resolveTicket(ticket.ID)
        },

        onClickAssignTicket(ticket) {
            this.$dialog.selectUser({
                title: "Выберете администратора",
                params: {},
            }).then((user) => {
                if (user) {
                    this.addTicketAssignee(ticket, user)
                }
            })
        },

        addTicketAssignee(ticket, user) {
            this.assignTicket({ ticket: ticket.ID, user: user.ID })
        },

        onClickDisplayInfo(ticket) {
            this.ticket = ticket
            this.visibleInfo = true
        },
    },
}
</script>
<style lang="stylus">
.page-tickets .support-view__heading {
  background-color: #c1c3c5;
}

.page-tickets .support-message__content {
  padding-bottom: 0;
  font-size: 15px;
}
</style>
